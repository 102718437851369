<template>
  <div>
    <v-row class="mt-2">
      <div class="v-col-xs-12 v-col-md-3 d-flex justify-center flex-xl-column text-center">
        <v-row class="d-flex justify-center flex-xl-column text-center">
          <div class="v-col-xs-12 mt-2">
            <ImageLogo class="d-block" />
          </div>
          <div class="v-col-xs-12 mx-3 mx-lg-0">
            <Element v-if="topText?.[0] && (topText?.[0]?.rawText || topText?.[0]?.tagName)" v-bind="topText[0]" />
          </div>
        </v-row>
      </div>

      <div
        v-if="props.links && props.links.length"
        :class="showHubs ? 'v-col-xs-12 v-col-md-6' : 'v-col-xs-12 v-col-md-9'"
      >
        <v-row class="mx-2">
          <v-col v-for="(link, i) in links" :key="i" class="footer-links" cols="12" md="6">
            <SmartLink v-if="link.uri" :to="link.uri">{{ link.label }}</SmartLink>
            <span v-else class="inner-link">{{ link.label }}</span>
          </v-col>
        </v-row>
      </div>
      <div
        v-if="showHubs"
        :class="
          props.links && props.links.length
            ? 'v-col-xs-12 v-col-md-3 structurizer pt-0'
            : 'v-col-xs-12 v-col-md-9 structurizer pt-0'
        "
      >
        <StructurizerTree />
      </div>
    </v-row>
    <div class="text-center my-2 copyright">
      <div>Copyright © {{ currentYear }}. All Rights Reserved.</div>
    </div>
  </div>
</template>
<script setup>
const page = inject("page");
const props = defineProps({
  links: { type: Array, required: false, default: () => [] },
  topText: { type: Object, required: false, default: () => ({}) },
  bottomText: { type: Array, required: false, default: () => [] },
  showHubs: { type: Boolean, required: false, default: true },
});
const showHubs = computed(() => {
  return props.showHubs && unref(page).structurizer;
});
const date = new Date();
const currentYear = date.toLocaleString("default", { year: "numeric" });
</script>
